#student-bg {
    position: relative;
    background-image: url("../images//front-view-smiley-students-posing-together\ 1.png");
    background-size: cover;
    background-position: center;
}

#student-bg::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #BC000077;
}